import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import Layout from "../fragments/layout/Layout";
import ImgGroup from "../components/ImgGroup/ImgGroup";
import GoBack from "../components/GoBack/GoBack";
import Seo from "../components/seo"

export const query = graphql`
  query ($slug: String!) {
    contentfulReportaje(slugUrl: { eq: $slug }) {
      titleSeo
      descriptionSeo {
        descriptionSeo
      }
      ao(formatString: "YYYY")
      mes
      medio
      titular
      imgenes {
        gatsbyImageData(placeholder: DOMINANT_COLOR, layout: CONSTRAINED)
      }
      portada {
        gatsbyImageData(placeholder: DOMINANT_COLOR, layout: CONSTRAINED)
      }
    }
  }
`;

const EjemploReportaje = (props) => {
  return (
    <>
      <Layout>
        <Seo
          title={props.data.contentfulReportaje.titular}
          lang="es"
          titleSEO={props.data.contentfulReportaje.titleSeo}
          description={
            props.data.contentfulReportaje.descriptionSeo.descriptionSeo
          }
        />
        <div className="GoBack-project" style={{ textAlign: "right" }}>
          <GoBack />
        </div> 
        <div className="carousel-news-container-xs text-center">
          <h1 style={{ marginBottom: 0 }}>
            {props.data.contentfulReportaje.titular}
          </h1>
          <p style={{ marginBottom: 30 }}>
            <small>
              {props.data.contentfulReportaje.mes}{" "}
              {props.data.contentfulReportaje.ao}
            </small>
          </p>
          <ImgGroup
            images={props.data?.contentfulReportaje?.imgenes}
            cover={props.data?.contentfulReportaje?.portada.gatsbyImageData}
          />
        </div>

        <div className="carousel-news-container-lg">
          <div className="row w-100">
            <div className="col p-0 text-center">
              <h1 style={{ marginBottom: 2, marginTop: 8 }}>
                {props.data.contentfulReportaje.titular}
              </h1>
              <h6 style={{ marginBottom: 25 }}>
                <small>
                  {props.data.contentfulReportaje.mes}{" "}
                  {props.data.contentfulReportaje.ao}
                </small>
              </h6>
            </div>
          </div>
          <div className="container">
            <div className="row justify-content-center">
              {props.data?.contentfulReportaje?.imgenes?.length <= 1 && (
                <GatsbyImage
                  image={getImage(
                    props.data?.contentfulReportaje?.portada.gatsbyImageData
                  )}
                  className="col-lg-6 col-md-6 mb-5 justify-content-center"
                  alt=""
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.3) 2px 2px 6px -1px, rgba(0, 0, 0, 0.1) 2px 2px 4px -1px",
                  }}
                />
              )}
              {props.data?.contentfulReportaje?.imgenes?.map((img, i) => (
                <GatsbyImage
                  key={i}
                  image={getImage(img)}
                  alt=""
                  className="col-lg-6 col-md-6 mb-5 justify-content-center"
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.3) 2px 2px 6px -1px, rgba(0, 0, 0, 0.1) 2px 2px 4px -1px",
                  }}
                />
              ))}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default EjemploReportaje;
