import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import './ImgGroup.scss'

const FourImgs = ({ images, cover }) => {
  return (
    <>
    <div className="">
      <div className="row groupImgs imgGroup-responsive-xs">
        <GatsbyImage
          image ={getImage(cover)}
          className="group-imgs mb-4"
          alt="img"
        />
        {
          images?.map((img, i) => (
            <div key={i} className="container-group-imgs col-md-6 mb-4 col-xs-12 justify-content-center">
              <GatsbyImage
                image ={getImage(img)}
                className="group-imgs"
                alt="img"
              />
            </div>
          ))
        } 
      </div>
      <div className="row groupImgs imgGroup-responsive-lg">
       {
        images?.map((img, i) => (
          <div key={i} className="container-group-imgs col-md-6 mb-4 col-xs-12 justify-content-center">
            <GatsbyImage
              image ={getImage(img)}
              className="group-imgs"
              alt="img"
            />
          </div>
        ))
      }
      </div>
    </div>
    </>
  )
}

export default FourImgs
