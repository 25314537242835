import React, { useEffect } from "react";
import Helmet from "react-helmet";
import useMetadata from "../../hooks/useMetadata";
import Menu from "../../components/Menu/Menu";
import ContainerFooter from "../../components/Footer/ContainerFooter";
import "./layout.scss";
import useViewport from "../../hooks/useViewport";

const Layout = React.memo(({ children }) => {
  const { title, description } = useMetadata();
  useViewport();

  useEffect(() => {
    const targetContainer = document.querySelector(".tl-wrapper");
    setTimeout(() => {
      targetContainer.style.transform = null;
    }, 1600);
  });

  return (
    <>
      <Helmet>
        <html lang="es" />
        <title>{title}</title>
        <meta name="description" content={description}></meta>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1"
        ></meta>
        <link
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.1/dist/css/bootstrap.min.css"
          rel="stylesheet"
          integrity="sha384-+0n0xVW2eSR5OomGNYDnhzAbDsOXxcvSN1TPprVMTNDbiYZCxYbOOl7+AMvyTG2x"
          crossOrigin="anonymous"
        ></link>
      </Helmet>

      <Menu />

      <div className="fatherContainer">{children}</div>

      <ContainerFooter />
    </>
  );
});

export default Layout;
