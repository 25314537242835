import React from 'react'
import Footer from './Footer'
import "./Footer.scss";

const ContainerFooter = ({extraClassName}) => {
  return (
    <div
      className={`footerContainer${extraClassName ? ` ${extraClassName}` : ""}`}
    >
      <Footer />
    </div>
  )
}

export default ContainerFooter
