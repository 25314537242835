import React from "react";
import MenuXl from "./MenuXl/MenuXl";
import MenuXs from "./MenuXs/MenuXs";
import "./menu.scss";

const Menu = () => {
  return (
    <>
      <div className="menu-responsive-lg">
        <MenuXl />
      </div>

      <div className="menu-responsive-xs">
        <MenuXs />
      </div>
    </>
  );
};

export default Menu;
