import React from "react";
import { Link } from "gatsby";
import logo from "../../../images/LOGO_ERD.png";
import "./MenuXl.scss";

const MenuXl = () => {
  const onClick = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="xlMenu">
      <h5 className="brand-desktop-menu">
        <Link className="brand-link" to="/">
          <img src={logo} alt="logo" style={{ width: 70 }} />
        </Link>
      </h5>
      <div className="contentMenu">
        <Link
          partiallyActive={true}
          className="navLink"
          activeClassName="active"
          to="/proyectos"
        >
          Proyectos
        </Link>
        <Link className="navLink" activeClassName="active" to="/estudio">
          Estudio
        </Link>
        <Link
          partiallyActive={true}
          className="navLink"
          activeClassName="active"
          to="/reportajes"
        >
          Reportajes
        </Link>
        <button className="navLink" onClick={onClick}>
          Contacto
        </button>
      </div>
    </div>
  );
};

export default MenuXl;
